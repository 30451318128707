import React from 'react';
import $ from 'jquery';
import convert from 'xml-js';
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";
import 'devextreme/data/odata/store';
import ODataStore from 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {confirm} from 'devextreme/ui/dialog';
import {API} from "aws-amplify";
import LDH from './LeopardDataHelper';
import LRH from './LeopardReactHelper';
import notify from 'devextreme/ui/notify';
import LeopardSecurity from '../security/LeopardSecurity';
import LeopardAjaxHelper from "./LeopardAjaxHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardReactHelper extends React.Component {
    constructor(props) {
        super(props);

        momentDurationFormatSetup(moment);
        if (typeof moment.duration.fn.format !== "function") {
            console.log("moment-duratio-format not supported.");
        }
        if (typeof moment.duration.format !== "function") {
            console.log("moment-duratio-format not supported.");
        }
    }

    static ConsoleLog = (comp, name, args) => {
        if (localStorage.getItem("ConsoleLog") === "lite") {
            console.log(name, args);
        } else if (localStorage.getItem("ConsoleLog") === "detail") {
            console.log(name, args, "##### Component #####", comp);
        }
    };

    static ShowToast = (text, type, displayTime) => {
        notify(text, type, displayTime);
    };

    static ShowDialog = (text, title, callbackOK, callbackCancel) => {
        confirm(text, title).then((dialogResult) => {
            if (dialogResult === true) {
                callbackOK();
            }
            if (callbackCancel !== undefined && callbackCancel !== null) {
                callbackCancel();
            }
        });
    };

    static ShowOrHideMenuLoadingProgress = (show) => {
        if (show) {
            setTimeout(() => {
                $(".leopard-screen-menucover").show();
            }, 100);
        } else {
            setTimeout(() => {
                $(".leopard-screen-menucover").hide();
            }, 100);
        }
    };

    static ShowUnhandledExceptionForMasterContent = () => {
        $(".leopard-right-panel-container .leopard-loading-icon").hide();
    };

    static ShowOrHideApplicationBackgroundCover = (show) => {
        if (show) {
            setTimeout(() => {
                $(".leopard-screen-cover").show();
            }, 100);
        } else {
            setTimeout(() => {
                $(".leopard-screen-cover").hide();
            }, 100);
        }
    };

    static BindValidationRulesToUIObject = (data, uiObjectInstance) => {
        let validationResult = true;
        let defaultBlankValue = data.defaultBlankValue;
        let instance = uiObjectInstance[data.input];

        if (instance === undefined || instance === null) {
            return validationResult;
        }
        if (instance.option("visible") === false) {
            return validationResult;
        }

        for (let i = 0; i < data.rules.length; i++) {
            let treatZeroAsNull = false;

            if (data.input === "validationInput") {
                validationResult = true;
                break;
            }

            if (data.input === "selectDataSourceInput") {
                treatZeroAsNull = true;
            }

            if (data.rules[i].rule === "required") {
                data.e.rule.message = "This field cannot be blank";
                if (LDH.IsValueEmpty(defaultBlankValue) === false) {
                    if (data.e.value === defaultBlankValue) {
                        validationResult = false;
                        break;
                    }
                } else {
                    if (LDH.IsValueEmpty(data.e.value) === true ||
                        (treatZeroAsNull && data.e.value === 0)) {
                        validationResult = false;
                        break;
                    }
                }
            }

            if (data.rules[i].rule === "safeinput") {
                data.e.rule.message = "This value is not safe";
                if (LeopardSecurity.IsInputValueDangerous(data.e.value)) {
                    validationResult = false;
                    break;
                }
            }

            if (data.rules[i].rule === "emailaddress-list") {
                data.e.rule.message = "Invalid email address detected";
                let emails = data.e.value;
                if (LDH.IsValueEmpty(emails)) {
                    emails = "";
                }
                let split = emails.split(";");
                for (let v = 0; v < split.length; v++) {
                    let email = split[v].trim();
                    if (LDH.IsValueEmpty(email)) continue;
                    if (!LDH.IsValidEmailAddress(email)) {
                        validationResult = false;
                        break;
                    }
                }
            }
        }
        return validationResult;
    };

    static SendAjaxForRetriveXmlFromReportDB(url, httpType, successFunc, errorFunc) {
        url = LDH.APIEndpointAdapter() + url;
        return $.ajax({
            type: httpType, url,
            error(error) {
                if (errorFunc !== undefined && errorFunc !== null) {
                    return errorFunc(error);
                }
                return null;
            }
        }).then(function (data) {
            let outerXml = data.documentElement.outerHTML;
            if (LDH.IsObjectNull(outerXml) && LDH.IsValueEmpty(outerXml)) {
                // This is a workaround for IE9+ support.
                outerXml = new XMLSerializer().serializeToString(data.documentElement);
            }
            let result = convert.xml2json(outerXml, {compact: false, spaces: 4});
            let resultJSON = JSON.parse(result);
            if (successFunc !== undefined && successFunc !== null) {
                return successFunc(resultJSON);
            }
            return resultJSON;
        });
    }

    static SendAjaxRequestForCharts(url, httpType, linqQuery, successFunc, errorFunc,
                                    dataViewId, chartDefinition, thisComp) {
        return API.get("aws-api-gateway", url, {
            headers: LeopardAjaxHelper.GenericRequestHeaders()
        }).then(data => {
            if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)){
                return [];
            }
            if (LDH.IsObjectNull(data) || LDH.IsObjectNull(data.value) ||
                Array.isArray(data.value) === false) {
                let rawDataToInsert = data;
                data = {};
                data["value"] = rawDataToInsert;
            }
            if (!LDH.IsObjectNull(thisComp) && thisComp.disposingAllInstances) {
                return [];
            }
            if (LDH.IsTimeoutReceivedFromAPIGateway(data) === true) {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(data, "retry");
                }
                return [];
            }
            if (!LDH.IsObjectNull(chartDefinition) && !LDH.IsValueEmpty(dataViewId) &&
                !LDH.IsObjectNull(chartDefinition.maximumDataThreshold) &&
                !LDH.IsValueEmpty(chartDefinition.maximumDataThreshold) &&
                chartDefinition.maximumDataThreshold > 0 && !LDH.IsObjectNull(data) &&
                data.value.length >= chartDefinition.maximumDataThreshold) {
                $("#Chart_TopBar_WarningIcon_" + dataViewId).show();
            } else {
                $("#Chart_TopBar_WarningIcon_" + dataViewId).hide();
            }
            if (LDH.IsValueEmpty(linqQuery) === false && !LDH.IsObjectNull(data) &&
                !LDH.IsObjectNull(data.value)) {
                LRH.ExecuteClientScript(data, linqQuery, "data", true);
            }
            if (LDH.IsObjectNull(data.value) === false) {
                if (successFunc !== undefined && successFunc !== null) {
                    successFunc(data.value, data);
                }
                return data.value;
            } else {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(data, "");
                }
                return [];
            }
        }).catch(error => {
            if (!LDH.IsObjectNull(thisComp) && thisComp.disposingAllInstances) {
                return [];
            }
            if (LeopardAjaxHelper.HandleSessionTimeoutEvent(error)){
                return [];
            }
            if (LDH.IsTimeoutReceivedFromAPIGateway(error) === true) {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(error, "retry");
                }
            } else {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(error, "");
                }
            }
            return [];
        });
    }

    static InitCustomStoreForChartView(url, httpType, dataViewId, serverSideQuery, linqQuery, successCallback,
                                       errorCallback, thisComp, isDataView, hasCustomQueryParams, chartDefinition) {
        return new DataSource({
            paginate: false,
            store: new CustomStore({
                loadMode: 'raw',
                load: () => {
                    let instance = thisComp.uiObjectInstance.chartInstance;
                    if (LDH.IsObjectNull(instance) === true) {
                        successCallback([]);
                        return [];
                    }

                    LDH.SetPostProcessResultForDataViewInstance(instance, isDataView,
                        hasCustomQueryParams, dataViewId);

                    let option = instance.option("setBlankPostProcess");
                    if (!LDH.IsObjectNull(option) && option === true) {
                        successCallback([]);
                        return [];
                    }

                    $("#gridViewToobar_" + dataViewId).show();
                    $("#Chart_TopBar_Refresh_" + dataViewId).addClass("leopard-ui-disabled");
                    url = window.ODataAPIGatewayUrl + serverSideQuery;
                    url = LDH.FilterMacro(url);

                    let customQueryParams = "";
                    if (!LDH.IsObjectNull(instance.option("customQueryParams")) &&
                        !LDH.IsValueEmpty(instance.option("customQueryParams"))) {
                        customQueryParams = instance.option("customQueryParams");
                    }

                    if (!LDH.IsObjectNull(instance.option("dataFromSource")) &&
                        !LDH.IsValueEmpty(instance.option("dataFromSource"))) {
                        let dataFromSource = instance.option("dataFromSource");
                        let sourceKeys = Object.keys(dataFromSource);
                        for (let i = 0; i < sourceKeys.length; i++) {
                            let sourceValue = dataFromSource[sourceKeys[i]];
                            if (LDH.IsObjectNull(sourceValue) || LDH.IsValueEmpty(sourceValue)) {
                                continue;
                            }
                            url = LDH.ReplaceAll(url, "{" + sourceKeys[i] + "}", sourceValue);
                        }
                    }

                    if (!LDH.IsObjectNull(customQueryParams) && !LDH.IsValueEmpty(customQueryParams)) {
                        url = LDH.ReplaceAll(url, "{linked-params}", customQueryParams);
                    } else {
                        url = LDH.ReplaceAll(url, "{linked-params}", "");
                    }

                    if (!LDH.IsObjectNull(chartDefinition.defaultValuesForQuery) &&
                        !LDH.IsValueEmpty(chartDefinition.defaultValuesForQuery)) {
                        let defaultValues = chartDefinition.defaultValuesForQuery.split(",");
                        for (let x = 0; x < defaultValues.length; x++) {
                            let valueSplit = defaultValues[x].split("|");
                            if (!LDH.IsObjectNull(valueSplit[1]) &&
                                valueSplit[1].toString().toLowerCase() === "'blank'") {
                                valueSplit[1] = "";
                            }
                            url = LDH.ReplaceAll(url, valueSplit[0], valueSplit[1]);
                        }
                    }

                    return LRH.SendAjaxRequestForCharts(url, httpType, linqQuery, function (data, response) {
                        successCallback(data, response);
                        return data;
                    }, function (error, errorText) {
                        if (thisComp.disposingAllInstances === true) {
                            return [];
                        }
                        if (LDH.IsObjectNull(errorText) || errorText !== "retry") {
                            LRH.ShowToast("Unable to retrieve the data for the charts.", "error", 5000);
                            $("#gridViewToobar_" + dataViewId).hide();
                            $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                        }
                        if (errorCallback !== undefined && errorCallback !== null) {
                            errorCallback(error, errorText);
                        }
                        return [];
                    }, dataViewId, chartDefinition, thisComp);
                }
            })
        });
    }

    static InitCustomStoreForAutoComplete(domain, url, autoCompleteOperation, columnName, gridDefinition, beforeSendCallback, postCallback) {
        let schemas = LDH.GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, url, columnName);
        let newUrl = schemas.tableName;
        let newColumnName = schemas.columnName;

        let urlToQuery = domain + window.ODataAPIGatewayUrl + "/" + newUrl +
            "?%24apply=groupby((" + newColumnName + "),aggregate(" + newColumnName + " with countdistinct as total))&authentication=true";

        if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
            urlToQuery = domain + window.ODataAPIGatewayUrl + "/" + newUrl +
                "?%24apply=groupby((" + newColumnName + ", " + gridDefinition.topLevelFilteringField.split("|")[0] + "),aggregate(" + newColumnName +
                " with countdistinct as total))&authentication=true";
        }

        return {
            store: {
                type: "odata",
                version: 4,
                url: urlToQuery,
                beforeSend: (xhr) => {
                    let schemas = LDH.GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, url, columnName);
                    let newColumnName = schemas.columnName;

                    if (!LDH.IsObjectNull(beforeSendCallback)) beforeSendCallback();
                    if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
                        if (LDH.IsObjectNull(xhr.params["$filter"]) || LDH.IsValueEmpty(xhr.params["$filter"])) {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] = "tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] = gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        } else {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] += " and tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] += " and " + gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(xhr.params["$filter"]) && !LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = LDH.FilterMacro(xhr.params["$filter"]);
                        xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], "(text)", "(" + newColumnName + ")");

                        if (autoCompleteOperation === "startswith") {
                            xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], "contains(", "startswith(");
                        }
                        xhr.params["$filter"] = LDH.ParseGuidForODataQuery(xhr.params["$filter"], newColumnName);
                    }
                    if (LDH.IsObjectNull(xhr.params["$top"]) || LDH.IsValueEmpty(xhr.params["$top"])) {
                        xhr.params["$top"] = 20;
                    }

                    if (LDH.IsObjectNull(gridDefinition.dataSourceCustomQueryTableSchema) === false &&
                        LDH.IsValueEmpty(gridDefinition.dataSourceCustomQueryTableSchema) === false) {
                        let tableSchemas = gridDefinition.dataSourceCustomQueryTableSchema.split("|");
                        for (let m = 0; m < tableSchemas.length; m++) {
                            if (tableSchemas[m].indexOf("{") > -1 && tableSchemas[m].indexOf("}") > -1 &&
                                LDH.IsObjectNull(xhr.params["$filter"]) === false &&
                                LDH.IsValueEmpty(xhr.params["$filter"]) === false) {
                                let startIndex = tableSchemas[m].indexOf("{") + 1;
                                let endIndex = tableSchemas[m].indexOf("}");
                                let prefix = tableSchemas[m].substring(startIndex, endIndex);
                                let newValue = xhr.params["$filter"];
                                newValue = LDH.ReplaceAll(newValue, prefix + "#", "");
                                xhr.params["$filter"] = newValue;
                            }
                        }
                    }
                },
                onLoaded: (result) => {
                    if (!LDH.IsObjectNull(postCallback)) postCallback();
                },
                errorHandler: (data) => {
                    if (!LDH.IsObjectNull(data) && typeof data === "object" && !LDH.IsObjectNull(data.httpStatus) &&
                        data.httpStatus === 504) {
                        LRH.ShowToast("This request has timed out, please try again.", "error", 5000);
                    }
                }
            },
            map(dataItem) {
                let dataJSON = dataItem;
                if (!LDH.IsValueEmpty(columnName) && columnName.indexOf("#") > -1) {
                    dataJSON = {};
                    let keys = Object.keys(dataItem);
                    let prefix = columnName.split("#")[0];
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] === "@odata.id" || keys[i] === "total") {
                            dataJSON[keys[i]] = dataItem[keys[i]];
                        } else {
                            dataJSON[prefix + "#" + keys[i]] = dataItem[keys[i]];
                        }
                    }
                }
                return dataJSON;
            },
            postProcess: (data) => {
                let list = [];
                if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)){
                    return [];
                }
                for (let i = 0; i < data.length; i++) {
                    let value = data[i][columnName];
                    list.push({[columnName]: value});
                }
                if (!LDH.IsObjectNull(postCallback)) postCallback();
                return list;
            }
        }
    }

    static InitCustomStoreForGridView(gridDefinition, gridViewId, url, linqQuery, limitedDataColumns,
                                      callbackFunc, fullColumns, thisComp, isDataView,
                                      hasCustomQueryParams) {
        let domainUrl = LDH.APIEndpointAdapter();
        let optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
        if (LDH.IsValueEmpty(optimizePagerForLargeDataset)) {
            optimizePagerForLargeDataset = true;
        }

        let gridViewRowKey = null;
        if (!LDH.IsObjectNull(gridDefinition.gridViewRowKey) && !LDH.IsValueEmpty(gridDefinition.gridViewRowKey)) {
            gridViewRowKey = gridDefinition.gridViewRowKey;
        }

        let allowSelect = LDH.IsObjectNull(gridDefinition.overrideDatabaseTableField) ||
            LDH.IsValueEmpty(gridDefinition.overrideDatabaseTableField) ||
            gridDefinition.overrideDatabaseTableField.indexOf("/topingroup") === -1;

        let ds = new DataSource({
            store: new ODataStore({
                version: 4,
                key: gridViewRowKey,
                url: domainUrl + window.ODataAPIGatewayUrl + "/" + url + "&authentication=true",
                beforeSend: (xhr) => {
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, false);
                    let instance = thisComp.uiObjectInstance.gridViewInstance;

                    LDH.SetPostProcessResultForDataViewInstance(instance, isDataView,
                        hasCustomQueryParams, gridViewId);

                    let customQueryParams = "";
                    if (!LDH.IsObjectNull(instance.option("customQueryParams")) &&
                        !LDH.IsValueEmpty(instance.option("customQueryParams"))) {
                        customQueryParams = instance.option("customQueryParams");
                    }

                    if (!LDH.IsObjectNull(instance.option("dataFromSource")) &&
                        !LDH.IsValueEmpty(instance.option("dataFromSource"))) {
                        let dataFromSource = instance.option("dataFromSource");
                        let sourceKeys = Object.keys(dataFromSource);
                        for (let i = 0; i < sourceKeys.length; i++) {
                            let sourceValue = dataFromSource[sourceKeys[i]];
                            if (LDH.IsObjectNull(sourceValue) || LDH.IsValueEmpty(sourceValue)) {
                                continue;
                            }
                            xhr.url = LDH.ReplaceAll(xhr.url, "{" + sourceKeys[i] + "}", sourceValue);
                        }
                    }

                    if (!LDH.IsObjectNull(gridDefinition.defaultValueLookupForOverrideDbField) &&
                        !LDH.IsValueEmpty(gridDefinition.defaultValueLookupForOverrideDbField)) {
                        let defaultValues = gridDefinition.defaultValueLookupForOverrideDbField.split(",");
                        for (let x = 0; x < defaultValues.length; x++) {
                            let valueSplit = defaultValues[x].split("|");
                            if (valueSplit[1].toLowerCase() === "'blank'") {
                                valueSplit[1] = "";
                            }
                            xhr.url = LDH.ReplaceAll(xhr.url, valueSplit[0], valueSplit[1]);
                        }
                    }

                    if (!LDH.IsObjectNull(customQueryParams) && !LDH.IsValueEmpty(customQueryParams)) {
                        let filter = xhr.params["$filter"];
                        if (LDH.IsObjectNull(filter) || LDH.IsValueEmpty(filter)) {
                            filter = "";
                        } else {
                            filter = " and " + filter;
                        }
                        xhr.params["$filter"] = customQueryParams + filter;
                    }

                    if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
                        if (LDH.IsObjectNull(xhr.params["$filter"]) || LDH.IsValueEmpty(xhr.params["$filter"])) {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] = "tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] = gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        } else {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] += " and tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] += " and " + gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(xhr.params["$filter"]) && !LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = LDH.FilterMacro(xhr.params["$filter"]);
                        xhr.params["$filter"] = LDH.ParseGuidForODataQuery(xhr.params["$filter"], "");
                    }

                    for (let v = 0; v < LeopardStaticUIConfig.Global_TempDateConvertion.length; v++) {
                        let tempDate = LeopardStaticUIConfig.Global_TempDateConvertion[v];
                        if (!LDH.IsObjectNull(tempDate) && !LDH.IsObjectNull(xhr.params["$filter"]) &&
                            !LDH.IsValueEmpty(xhr.params["$filter"]) && tempDate.dataViewId === gridViewId) {
                            xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"],
                                tempDate.convertFrom, tempDate.convertTo);
                        }
                    }

                    if (optimizePagerForLargeDataset === true) {
                        xhr.params["$count"] = false;

                        let filterString = xhr.params["$filter"];
                        let filterHistory = thisComp.combinedFilterHistory;
                        let historyLength = filterHistory.length - 2;
                        if (filterHistory.length > 5000) {
                            if (filterHistory[historyLength] !== filterString) {
                                filterHistory = [];
                                filterHistory.push("-");
                            } else {
                                filterHistory = [];
                            }
                        }
                        filterHistory.push(filterString);

                        if (!LDH.IsObjectNull(filterHistory) && historyLength > 1 &&
                            filterHistory[historyLength] !== filterString) {
                            instance.option("customPagingOperation", "");
                            instance.option("customPagingIndex", 0);
                        }

                        if (LDH.IsObjectNull(instance) === false) {
                            let operation = instance.option("customPagingOperation");
                            let pageIndex = instance.option("customPagingIndex");
                            let pageSize = instance.option("customPagingSize");

                            if (LDH.IsValueEmpty(pageSize) === true) {
                                pageSize = thisComp.props.gridDefinition.defaultPageSize;
                            }
                            if (!LDH.IsValueEmpty(operation) && operation === "next") {
                                instance.option("customPagingRefresh", false);
                                if (pageIndex + 1 < 0) pageIndex = 0;
                                xhr.params["$skip"] = pageIndex * pageSize;
                                instance.option("customPagingIndex", pageIndex);
                            } else if (!LDH.IsValueEmpty(operation) && operation === "prev") {
                                if (pageIndex - 1 < 0) pageIndex = 0;
                                xhr.params["$skip"] = pageIndex * pageSize;
                                instance.option("customPagingIndex", pageIndex);
                            } else {
                                xhr.params["$skip"] = 0;
                                instance.option("customPagingIndex", 0);
                            }
                            xhr.params["$top"] = pageSize;
                        }
                    }
                    $("#" + gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").hide();

                    let option = instance.option("setBlankPostProcess");
                    if (!LDH.IsObjectNull(option) && option === true) {
                        xhr.params["$top"] = 0;
                        xhr.params["$skip"] = 0;
                    }

                    if (xhr.url.indexOf("$top=") > -1 && !LDH.IsObjectNull(xhr.params["$top"]) &&
                        !LDH.IsValueEmpty(xhr.params["$top"]) && !isDataView){
                        delete xhr.params["$top"];
                    }

                    let paramKeys = Object.keys(xhr.params);
                    if (!LDH.IsObjectNull(paramKeys) && paramKeys.length > 0) {
                        for (let v = 0; v < paramKeys.length; v++) {
                            let originalValue = xhr.params[paramKeys[v]];
                            if (LDH.IsValueEmpty(originalValue) ||
                                typeof originalValue !== 'string') {
                                continue;
                            }
                            originalValue = LDH.ReplaceAll(originalValue, "#", "/");
                            xhr.params[paramKeys[v]] = originalValue;
                        }
                    }
                }
            }),
            requireTotalCount: true,
            select: allowSelect ? LDH.GetIncludedColumns(limitedDataColumns, fullColumns) : null,
            map(dataItem) {
                let dataJSON = {};
                LDH.MapJsonObjectForGridViewData(dataItem, dataJSON, null);
                return dataJSON;
            },
            postProcess: (data) => {
                if (thisComp.disposingAllInstances) {
                    return [];
                }
                if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)){
                    return [];
                }
                if (LDH.IsValueEmpty(linqQuery) === false) {
                    LRH.ExecuteClientScript(data, linqQuery, "data", true);
                }
                setTimeout(function () {
                    callbackFunc(data, ds);
                }, 10);
                return data;
            },
            onLoadingChanged: (isLoading) => {
                if (isLoading === false && !thisComp.disposingAllInstances) {
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
                }
            }
        });
        return ds;
    }

    static InitCustomStoreForGridViewFilter(domain, url, columnName, gridDefinition) {
        let schemas = LDH.GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, url, columnName);
        url = schemas.tableName;
        columnName = schemas.columnName;

        let urlToQuery = domain + window.ODataAPIGatewayUrl + "/" + url +
            "?%24apply=groupby((" + columnName + "),aggregate(" + columnName + " with countdistinct as total))&authentication=true";

        if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
            urlToQuery = domain + window.ODataAPIGatewayUrl + "/" + url +
                "?%24apply=groupby((" + columnName + ", " + gridDefinition.topLevelFilteringField.split("|")[0] + "),aggregate(" +
                columnName + " with countdistinct as total))&authentication=true";
        }

        return {
            store: {
                type: "odata",
                version: 4,
                url: urlToQuery,
                beforeSend: (xhr) => {
                    if (!LDH.IsObjectNull(gridDefinition.topLevelFilteringField) && !LDH.IsValueEmpty(gridDefinition.topLevelFilteringField)) {
                        if (LDH.IsObjectNull(xhr.params["$filter"]) || LDH.IsValueEmpty(xhr.params["$filter"])) {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] = "tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] = gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        } else {
                            if (gridDefinition.topLevelFilteringField.indexOf("'") > -1) {
                                xhr.params["$filter"] += " and tolower(" + gridDefinition.topLevelFilteringField.split("|")[0] + ") eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            } else {
                                xhr.params["$filter"] += " and " + gridDefinition.topLevelFilteringField.split("|")[0] + " eq " +
                                    gridDefinition.topLevelFilteringField.split("|")[1];
                            }
                        }
                    }

                    if (!LDH.IsObjectNull(xhr.params["$filter"]) && !LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = LDH.FilterMacro(xhr.params["$filter"]);
                        xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], "(text)", "(" + columnName + ")");
                        xhr.params["$filter"] = LDH.ParseGuidForODataQuery(xhr.params["$filter"], columnName);
                    }
                }
            },
            postProcess: (data) => {
                let list = [];
                if (LeopardAjaxHelper.HandleSessionTimeoutEvent(data)){
                    return [];
                }
                for (let i = 0; i < data.length; i++) {
                    let value = data[i][columnName];
                    list.push({text: value, value});
                }
                return list;
            }
        }
    }

    static EnableOrDisableGridViewToolbarButtons = (gridViewId, enabled) => {
        if (enabled === true) {
            $("#gridViewToobar_" + gridViewId).hide();
            $("#GridView_TopBar_Refresh_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Import_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).hide();
            $("#GridViewPager_" + gridViewId).removeClass("leopard-ui-disabled");
        } else {
            $("#gridViewToobar_" + gridViewId).show();
            $("#GridView_TopBar_Refresh_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Import_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).show();
            $("#GridViewPager_" + gridViewId).addClass("leopard-ui-disabled");
        }
    };

    static SearchForMenuItems = (inputValue) => {
        $(".leopard-leftmenu-item .leopard-leftmenu-item-text").each(function () {
            let value = $(this).attr("searchabletext");
            if (LDH.IsObjectNull(value) || LDH.IsValueEmpty(value)) {
                return;
            }
            if (LDH.IsValueEmpty(inputValue.toString().toLowerCase().trim())) {
                $(this).closest(".leopard-leftmenu-item").css("display", "flex");
                $(this).closest(".leopard-leftmenu-item").addClass("item-found");
                return;
            }
            let text = value.toString().toLowerCase().trim();
            if (text.indexOf(inputValue.toString().toLowerCase().trim()) > -1) {
                $(this).closest(".leopard-leftmenu-item").css("display", "flex");
                $(this).closest(".leopard-leftmenu-item").addClass("item-found");
            } else {
                $(this).closest(".leopard-leftmenu-item").hide();
                $(this).closest(".leopard-leftmenu-item").removeClass("item-found");
            }
        });

        $(".menu-item-totalcount").each(function () {
            let category = $(this).attr("category");
            let $root = $(".dataViewCategory_" + category);
            let count = 0;
            $(".leopard-leftmenu-item", $root).each(function () {
                if ($(this).hasClass("item-found")) count++;
            });
            $(this).text(count);
        });
    };

    static TriggerWindowResizeEvent() {
        if (typeof (Event) === 'function') {
            window.dispatchEvent(new Event('resize'));
        } else {
            let evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    }

    static DisposeUIInstancesFromList(uiObjectInstances) {
        if (!LDH.IsObjectNull(uiObjectInstances)) {
            let keys = Object.keys(uiObjectInstances);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                uiObjectInstances[key].instance.dispose();
            }
        }
    }

    static DownloadStringToFile(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    static AddDisposablePopupInstances(thisComp) {
        let instances = [];
        let instanceKeys = Object.keys(thisComp.uiObjectInstances);
        for (let i = 0; i < instanceKeys.length; i++) {
            let key = instanceKeys[i];
            instances.push(thisComp.uiObjectInstances[key]);
        }
        thisComp.props.addDisposablePopupInstances(instances);
    }

    static ExecuteClientScript(data, linqQuery, dataType, clearReference) {
        try {
            if (LDH.IsObjectNull(dataType) || LDH.IsValueEmpty(dataType)) {
                dataType = "data";
            }

            window[dataType] = data;
            window.moment = moment;
            window.__callback = function () {
                LRH.ShowToast("Your client-side query could not be processed. Raw data is being used.", "warning", 5000);
            };
            let query = LDH.GetClientSideQuery(linqQuery, dataType);
            $.globalEval(query);

            let dataToReturn = window[dataType];
            if (clearReference && !LDH.IsObjectNull(window[dataType])) {
                window[dataType] = null;
            }
            window.moment = null;
            return dataToReturn;
        } catch (ex) {
            LRH.ShowToast("Your client-side query could not be processed. Raw data is being used.", "warning", 5000);
        }
    }

    static ApplyCustomStyle(styleDefinition, version) {
        if (styleDefinition !== undefined && styleDefinition !== null &&
            styleDefinition.length > 0) {
            let defaultStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href="/css/custom.css?v=' + version + '" />';
            let customStyle = '<style id="leopard-custom-style" type="text/css">' + styleDefinition + '</style>';

            $("#leopard-default-style").remove();
            $("head").append(defaultStyle);

            $("#leopard-custom-style").remove();
            $("head").append(customStyle);
        } else {
            $("#leopard-custom-style").remove();
        }
    }
}

export default LeopardReactHelper;
