import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import LeopardAttachmentPopup from '../components/LeopardAttachmentPopup';
import moment from "moment";

import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing,
    Editing,
    Export,
    FilterRow,
    HeaderFilter,
    LoadPanel,
    Pager,
    Paging,
    RemoteOperations,
    Selection,
    Sorting
} from 'devextreme-react/data-grid';

import ExcelJS from 'exceljs';
import {exportDataGrid} from 'devextreme/excel_exporter';

import LeopardSecurity from '../security/LeopardSecurity';
import LDH from '../helpers/LeopardDataHelper';
import LRH from '../helpers/LeopardReactHelper';
import {InitCustomStore, UpdateGridViewDefinition} from './LeopardActionCreators';
import {LeopardGridViewColumnBuilder, LeopardGridViewToolbar} from '../datashaping/LeopardGridViewBuilders';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardFormEditor from "./LeopardFormEditor";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LeopardDataImportPopup from "../components/LeopardDataImportPopup";
import LPH from "../helpers/LeopardPermissionHelper";

class LeopardGridView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupingEnabled: false,

            // ---- For "UseStateStore => True" Only ----
            customStore: null
            // ------------------------------------------
        };

        this.totalRecordCount = 0;
        this.optimizePagerForLargeDataset = true;
        this.defaultPageSize = 10;
        this.enableAutoRefresh = false;
        this.autoRefreshInterval = 30;
        this.customColumnOverallWidth = 200;
        this.showAutoRefreshSwitch = true;
        this.gridDefinition = {columnDefinition: []};
        this.isGridViewLayoutStateLoaded = false;
        this.customColumnConfiguration = null;
        this.retryConnectionCount = 0;
        this.hasCustomizeColumnsInitialized = false;
        this.combinedFilterHistory = [];
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.isFirstRowSelectedOnInitLoad = false;
        this.disposingAllInstances = false;

        this.uiObjectInstance = {
            popupDataImportInstance: null,
            gridViewInstance: null,
            popupEditFormInstance: null,
            popupPhotoGalleryInstance: null
        };
        this.disposablePopupInstances = [];
    }

    setGridViewInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null) return;
        this.uiObjectInstance.gridViewInstance = ref.instance;

        this.props.setGridViewInstance({
            instance: ref.instance,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.GridViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.gridViewInstance.option("relationships", linkedList);
        }

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback(data) {
                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let gridDefinition = this.props.gridDefinition;
                            let params = gridDefinition.oDataQueryForLinkedView;
                            let columDef = [];
                            if (!LDH.IsObjectNull(data.parentGridDef) &&
                                !LDH.IsObjectNull(data.parentGridDef.columnDefinition)) {
                                columDef = data.parentGridDef.columnDefinition;
                            }
                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (LDH.IsObjectNull(data.dataFromSource)) {
                                    this.instance.option("setBlankPostProcess", true);

                                    let gridViewState = that.props.state.gridViewState;
                                    let currentState = that.getCurrentState(gridViewState, false);
                                    let filterOption = gridDefinition.clearFilterOnParentSelectionChanged;
                                    if ((LDH.IsObjectNull(filterOption) || filterOption === true) &&
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).length > 0) {
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).trigger("click");
                                    }
                                    this.instance.option("dataSource", currentState.customStore);
                                } else {
                                    params = LDH.ConvertArrayMacroToString(params,
                                        data.dataFromSource, columDef);

                                    this.instance.option("dataFromSource", data.dataFromSource);
                                    this.instance.option("customQueryParams", params);
                                    this.instance.option("setBlankPostProcess", false);
                                    this.instance.option("selectFirstRow", true);

                                    let gridViewState = that.props.state.gridViewState;
                                    let currentState = that.getCurrentState(gridViewState, false);
                                    let filterOption = gridDefinition.clearFilterOnParentSelectionChanged;
                                    if ((LDH.IsObjectNull(filterOption) || filterOption === true) &&
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).length > 0) {
                                        $("#GridView_TopBar_ClearFilter_" + dashboardItemId).trigger("click");
                                    }
                                    this.instance.option("dataSource", currentState.customStore);
                                }
                            } else if ((LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) &&
                                !LDH.IsObjectNull(data.dataFromSource)) {
                                this.instance.option("dataFromSource", data.dataFromSource);
                                this.instance.option("setBlankPostProcess", false);
                                this.instance.option("selectFirstRow", true);

                                let gridViewState = that.props.state.gridViewState;
                                let currentState = that.getCurrentState(gridViewState, false);
                                let filterOption = gridDefinition.clearFilterOnParentSelectionChanged;
                                if ((LDH.IsObjectNull(filterOption) || filterOption === true) &&
                                    $("#GridView_TopBar_ClearFilter_" + dashboardItemId).length > 0) {
                                    $("#GridView_TopBar_ClearFilter_" + dashboardItemId).trigger("click");
                                }
                                this.instance.option("dataSource", currentState.customStore);
                            }
                        }
                    }
                }
            });
        }
    };

    componentDidMount = () => {
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            this.initializeCustomStore(true);
        } else {
            this.initializeCustomStore(false);
        }
    };

    componentWillUnmount = () => {
        let instance = this.uiObjectInstance.gridViewInstance;
        this.disposingAllInstances = true;

        if (instance !== undefined && instance !== null) {
            instance.dispose();
            let elem = document.getElementById(this.props.GridViewId);
            elem.parentNode && elem.parentNode.removeChild(elem);
        }

        let popupInstances = this.disposablePopupInstances;
        for (let i = 0; i < popupInstances.length; i++) {
            if (LDH.IsObjectNull(popupInstances[i]) ||
                LDH.IsObjectNull(popupInstances[i].instance)) {
                continue;
            }
            popupInstances[i].instance.dispose();
        }

        if (!LDH.IsObjectNull(this.uiObjectInstance.popupEditFormInstance)) {
            this.uiObjectInstance.popupEditFormInstance.dispose();
        }
        if (!LDH.IsObjectNull(this.uiObjectInstance.popupPhotoGalleryInstance)) {
            this.uiObjectInstance.popupPhotoGalleryInstance.dispose();
        }
        if (!LDH.IsObjectNull(this.uiObjectInstance.popupDataImportInstance)) {
            this.uiObjectInstance.popupDataImportInstance.dispose();
        }

        this.props.setGridViewInstance({
            instance: null,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });
    };

    initializeCustomStore = (isDataView) => {
        let thisComp = this;
        let gridViewId = this.props.GridViewId;
        let gridDefinition = this.props.gridDefinition;
        let limitedColumns = [];
        let clientSideQuery = "";

        for (let u = 0; u < gridDefinition.columnDefinition.length; u++) {
            if (!LDH.IsValueEmpty(gridDefinition.columnDefinition[u].isEnabled) &&
                gridDefinition.columnDefinition[u].isEnabled === false) {
                limitedColumns.push(gridDefinition.columnDefinition[u].columnName);
            }
        }

        if (!LDH.IsValueEmpty(gridDefinition.clientSideQuery)) {
            clientSideQuery = gridDefinition.clientSideQuery;
        }

        let hasCustomQueryParams = false;
        if (!LDH.IsObjectNull(gridDefinition.oDataQueryForLinkedView) &&
            !LDH.IsValueEmpty(gridDefinition.oDataQueryForLinkedView)) {
            hasCustomQueryParams = true;
        }

        let url = this.props.GetDataFromUrl.replace("?tableName=", "");
        let fullColumns = this.props.columnFieldList.fullColumns;

        let store = LRH.InitCustomStoreForGridView(gridDefinition, gridViewId, url, clientSideQuery,
            limitedColumns, function (gridData, ds) {
                if (thisComp.disposingAllInstances === true) {
                    return;
                }
                thisComp.totalRecordCount = ds.totalCount();
                if (!LDH.IsObjectNull(gridData) && !LDH.IsObjectNull(gridData.length)) {
                    thisComp.disableOrEnablePagerBasedOnRowCount(gridData.length, gridViewId);
                }
                $(".toolbar-warming-up-text", "#gridViewToobar_" + gridViewId).hide();
                thisComp.validateUserAuthenticationStatus();
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);

                let instance = thisComp.uiObjectInstance.gridViewInstance;
                let selectByDefault = LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault;

                if (!LDH.IsObjectNull(instance) && gridData.length > 0 &&
                    !LDH.IsObjectNull(instance.option("selectFirstRow")) &&
                    instance.option("selectFirstRow") && !isDataView) {
                    instance.selectRowsByIndexes(0);
                    instance.option("selectFirstRow", false);
                } else if (!isDataView && thisComp.props.dashboardLevel === 1 &&
                    thisComp.isFirstRowSelectedOnInitLoad === false) {
                    thisComp.isFirstRowSelectedOnInitLoad = true;
                    instance.selectRowsByIndexes(0);
                } else if (!isDataView && selectByDefault === true) {
                    thisComp.isFirstRowSelectedOnInitLoad = true;
                    instance.selectRowsByIndexes(0);
                }
            }, fullColumns, this, isDataView, hasCustomQueryParams);

        if (thisComp.props.dataInitializedOnControls === false) {
            thisComp.props.dataInitializedOnControlsUpdateRequest();
        }

        if (isDataView === false) {
            this.gridDefinition = thisComp.props.gridDefinition;
            this.setState({customStore: store});
        } else {
            this.props.InitCustomStore(store, gridViewId);
            this.props.UpdateGridViewDefinition(gridDefinition, gridViewId);
            this.props.applyGridViewConfigurationOnDataView({
                filterBuilderValue: gridDefinition.filterBuilderValue,
                explicitFilterOption: gridDefinition.explicitFilterOption,
                columnResizingMode: gridDefinition.columnResizingMode
            });
        }
    };

    validateUserAuthenticationStatus = () => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
        }, function (error) {
            LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
        });
    };

    getCurrentState = (gridViewState, setThisAsStore) => {
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = gridViewState.filter(gridView => {
                return gridView.gridViewId === this.props.GridViewId;
            });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        } else {
            if (setThisAsStore) {
                currentState = this;
            } else if (this.state.customStore === null) {
                currentState = null;
            }
        }
        if (currentState === undefined || currentState === null || currentState.length === 0) {
            currentState = null;
        }
        return currentState;
    };

    onRowExpanding = (e) => {
        e.component.collapseAll(-1);
    };

    onToolbarPreparing = (e) => {
        let id = this.props.GridViewId.toString();
        let headerPanelId = "#" + id + " .dx-datagrid-header-panel";
        if (this.state.groupingEnabled === false) {
            $(headerPanelId).hide();
        } else {
            $(headerPanelId).show();
        }

        let toolbarItems = e.toolbarOptions.items;
        for (let i = 0; i < toolbarItems.length; i++) {
            if (toolbarItems[i].name === "addRowButton" ||
                toolbarItems[i].name === "exportButton") {
                toolbarItems[i].visible = false;
            }
        }
    };

    onInitialized = (e) => {
        e.component.columnOption("command:edit", "width", 100);
    };

    onDataErrorOccurred = (e) => {
        let that = this;
        let gridViewId = this.props.GridViewId;

        if (!LDH.IsObjectNull(e) && typeof e === "object" && !LDH.IsObjectNull(e.error) &&
            !LDH.IsObjectNull(e.error.httpStatus) && e.error.httpStatus === 403) {
            setTimeout(function () {
                let $root = $("#" + gridViewId);
                let $error = $(".dx-error-row .dx-error-message", $root);
                $error.text("Failed to request data from the server. Please refresh your browser and try again.");
            }, 100);
            return;
        }

        if ((LDH.IsTimeoutReceivedFromAPIGateway(e)) && this.retryConnectionCount < 10) {
            this.retryConnectionCount += 1;
            $(".toolbar-warming-up-text", $("#gridViewToobar_" + gridViewId)).show();

            setTimeout(function () {
                let $root = $("#" + gridViewId);
                let $error = $(".dx-error-row .dx-error-message", $root);
                $error.addClass("leopard-error-message-override").text("");
                $error.closest(".dx-error-row").hide();

                let instance = that.uiObjectInstance.gridViewInstance;
                let gridViewState = that.props.state.gridViewState;
                let currentState = that.getCurrentState(gridViewState, false);
                instance.option("dataSource", currentState.customStore);
            }, 100);
        } else {
            if (that.props.dataInitializedOnControls === false) {
                that.props.dataInitializedOnControlsUpdateRequest();
            }
            $(".toolbar-warming-up-text", $("#gridViewToobar_" + gridViewId)).hide();

            setTimeout(function () {
                let $root = $("#" + gridViewId);
                let $error = $(".dx-error-row .dx-error-message", $root);
                $error.removeClass("leopard-error-message-override");
            }, 100);
            this.retryConnectionCount = 0;
            LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
        }
    };

    registerGridViewColumnHeaderEvents = () => {
        let that = this;
        $(".dx-texteditor-input-container", "#" + this.props.GridViewId).each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });

        $(".dx-overlay-wrapper.dx-datagrid-filter-range-overlay .dx-texteditor-input-container").each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });
    };

    resetPagingAndRefresh = (isRefresh, gridViewId) => {
        let $pager = $(".leopard-page-number.selected", "#GridViewPager_" + gridViewId);

        let pageSize = this.uiObjectInstance.gridViewInstance.pageSize();
        if (!LDH.IsObjectNull($pager) && $pager.length > 0) {
            pageSize = parseInt($pager.attr("pageSize").trim());
        }

        this.uiObjectInstance.gridViewInstance.option("customPagingOperation", "");
        this.uiObjectInstance.gridViewInstance.option("customPagingIndex", 0);
        this.uiObjectInstance.gridViewInstance.option("customPagingSize", pageSize);
        if (isRefresh) {
            let instance = this.uiObjectInstance.gridViewInstance;
            let gridViewState = this.props.state.gridViewState;
            let currentState = this.getCurrentState(gridViewState, false);
            instance.option("dataSource", currentState.customStore);
        }
    };

    setPagerLightMode = (e) => {
        try {
            let pagerView = e.component.getView("pagerView");
            if (!LDH.IsObjectNull(pagerView) && !this.optimizePagerForLargeDataset) {
                let pager = pagerView.element().dxPager("instance");
                if (e.element.clientWidth <= 495) {
                    pager.option("lightModeEnabled", true);
                } else {
                    pager.option("lightModeEnabled", false);
                }
            }
        } catch (error) {

        }
    };

    onExporting = (e) => {
        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('ExportResult');
        let gridDefinition = currentState.gridDefinition;

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            topLeftCell: {row: 1, column: 1},
            customizeCell: (options) => {
                const {excelCell, gridCell} = options;
                if (gridCell.rowType === 'data' &&
                    !LDH.IsObjectNull(gridDefinition.dataExportJavaScript) &&
                    !LDH.IsValueEmpty(gridDefinition.dataExportJavaScript)) {
                    window.moment = moment;
                    eval(gridDefinition.dataExportJavaScript);
                }
            }
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                let blob = new Blob([buffer], {type: "application/octet-stream"});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                let fileName = "ExportResult.xlsx";
                link.download = fileName;
                link.click();
            });
        });
        e.cancel = true;
    };

    onContentReady = (e) => {
        let that = this;
        let $grid = $("#" + this.props.GridViewId);
        that.setPagerLightMode(e);

        // ------ Temporary hide the group panel for now until we work on it. ----------
        let headerPanelId = "#" + this.props.GridViewId + " .dx-datagrid-header-panel";
        $(headerPanelId).hide();
        // -----------------------------------------------------------------------------

        let gridViewId = this.props.GridViewId;
        if (that.optimizePagerForLargeDataset === true) {
            $(".leopard-page-number", "#GridViewPager_" + gridViewId).each(function () {
                $(this).off("click").on("click", function () {
                    if ($("#GridViewPager_" + gridViewId).hasClass("leopard-ui-disabled")) {
                        return false;
                    }
                    let $id = $("#GridViewPager_" + gridViewId);
                    $(".leopard-page-number", $id).removeClass("selected");
                    $(this).addClass("selected");
                    that.resetPagingAndRefresh(true, gridViewId);
                    return false;
                });
            });
        }

        $(".leopard_gridview_column_template .dx-editor-with-menu", $grid).each(function () {
            $(this).off("mousedown").on("mousedown", function () {
                setTimeout(function () {
                    that.registerGridViewColumnHeaderEvents();
                }, 100);
            });
        });
        $(".leopard_gridview_column_template .dx-menu-item-wrapper", $grid).each(function () {
            $(this).off("mouseenter").on("mouseenter", function () {
                setTimeout(function () {
                    $(".dx-overlay-wrapper .dx-menu-item-content").each(function () {
                        $(this).off("click").on("click", function () {
                            setTimeout(function () {
                                that.registerGridViewColumnHeaderEvents();
                            }, 500);
                        })
                    });
                }, 500);
            });
        });
        that.registerGridViewColumnHeaderEvents();
    };

    groupingButtonOnClick = () => {
        let id = this.props.GridViewId;
        let headerPanelId = "#" + id.toString() + " .dx-datagrid-header-panel";
        if (this.state.groupingEnabled === false) {
            this.setState({groupingEnabled: true});
            $(headerPanelId).show();
        } else {
            this.setState({groupingEnabled: false});
            $(headerPanelId).hide();
        }
        this.uiObjectInstance.gridViewInstance.updateDimensions();
    };

    viewOptionsButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ViewOptions_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance.gridViewInstance.showColumnChooser();
    };

    addRowButtonOnClick = (data) => {
        let that = this;
        data.getDataFromUrl = that.props.GetDataFromUrl;
        data.getDataFromUrl = data.getDataFromUrl.replace("?tableName=", "");
        LeopardStaticUIConfig.Global_PopupCustomColumnData = data;

        let instance = that.uiObjectInstance.popupEditFormInstance;
        LeopardStaticUIConfig.Global_PopupTempObjectInstance = instance;
        instance.option("visible", true);
    };

    exportButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Export_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        let rowLimit = LeopardStaticUIConfig.GridView_ExportMaximumRowLimit;
        if (!LDH.IsObjectNull(currentState.gridDefinition.exportMaximumRowLimit) &&
            !LDH.IsValueEmpty(currentState.gridDefinition.exportMaximumRowLimit)){
            rowLimit = currentState.gridDefinition.exportMaximumRowLimit;
        }
        if (this.totalRecordCount > rowLimit) {
            LRH.ShowToast("We are unable to export your data. Make sure your data has less than " +
                rowLimit + " rows.", "error", 5000);
            return;
        }
        this.uiObjectInstance.gridViewInstance.exportToExcel(false);
    };

    importButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Import_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        $("#GridView_TopBar_ImportWidget_" + data.gridViewId).val(null).trigger("click");
    };

    importDataCallback = (e) => {
        let instance = this.uiObjectInstance.popupDataImportInstance;
        LeopardStaticUIConfig.Global_PopupTempData = {
            data: LDH.ConvertCsvToJSON(e.data),
            definition: this.props.gridDefinition
        };
        LeopardStaticUIConfig.Global_PopupTempObjectInstance = instance;
        $("#GridView_TopBar_ImportWidget_" + e.gridViewId).val(null);
        instance.option("visible", true);
    };

    refreshButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Refresh_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        instance.option("dataSource", currentState.customStore);
    };

    applyFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ApplyFilter_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        $("#" + data.gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").trigger("click");
    };

    clearFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ClearFilter_" + data.e.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.resetPagingAndRefresh(false, data.e.gridViewId);
        this.uiObjectInstance.gridViewInstance.clearFilter();

        if (!LDH.IsObjectNull(data.gridDefinition.removeColumnSortOnFilterCleared) &&
            data.gridDefinition.removeColumnSortOnFilterCleared === true) {
            this.uiObjectInstance.gridViewInstance.clearSorting();
        }
    };

    buttonPrevOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.previous", $root)
                .hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let pageIndex = instance.option("customPagingIndex");
        if (!LDH.IsValueEmpty(pageIndex)) {
            pageIndex = parseInt(pageIndex) - 1;
            if (pageIndex < 0) pageIndex = 0;
        } else {
            pageIndex = 0;
        }
        instance.option("customPagingIndex", pageIndex);
        instance.option("customPagingRefresh", true);
        instance.option("customPagingOperation", "prev");

        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        instance.option("dataSource", currentState.customStore);
    };

    buttonNextOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.next", $root)
                .hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let pageIndex = instance.option("customPagingIndex");
        if (!LDH.IsValueEmpty(pageIndex)) {
            pageIndex = parseInt(pageIndex) + 1;
            if (pageIndex < 0) pageIndex = 0;
        } else {
            pageIndex = 0;
        }
        instance.option("customPagingIndex", pageIndex);
        instance.option("customPagingOperation", "next");

        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        instance.option("dataSource", currentState.customStore);
    };

    disableOrEnablePagerBasedOnRowCount = (rowCount, gridViewId) => {
        let $root = $("#GridViewPager_" + gridViewId);
        if ($root.length === 0) return;
        let currentPageInt = 1;
        let instance = this.uiObjectInstance.gridViewInstance;
        if (!LDH.IsObjectNull(instance) &&
            !LDH.IsValueEmpty(instance.option("customPagingIndex"))) {
            currentPageInt = parseInt(instance.option("customPagingIndex")) + 1;
        }

        let firstPageDisabled = false;
        if (currentPageInt <= 1) firstPageDisabled = true;

        if (rowCount === 0 && currentPageInt <= 1) {
            $(".leopard-page-button-container.previous", $root).addClass("disabled");
            $(".leopard-page-button-container.next", $root).addClass("disabled");
        } else if (rowCount === 0 && currentPageInt > 1) {
            $(".leopard-page-button-container.previous", $root).removeClass("disabled");
            $(".leopard-page-button-container.next", $root).addClass("disabled");
        } else {
            let pageSize = parseInt($(".leopard-page-number.selected", $root).attr("pagesize"));
            if (pageSize <= rowCount) {
                $(".leopard-page-button-container.next", $root).removeClass("disabled");
            } else {
                $(".leopard-page-button-container.next", $root).addClass("disabled");
            }
            if (firstPageDisabled) {
                $(".leopard-page-button-container.previous", $root).addClass("disabled");
            } else {
                $(".leopard-page-button-container.previous", $root).removeClass("disabled");
            }
        }
        $(".leopard-pagenumber-current", $root).text(currentPageInt);
    };

    onEditorPreparing = (data) => {
        let e = data.e;

        if (e.parentType === "filterRow" && e.dataType === "datetime") {
            let columnDefinitionList = data.gridDefinition.columnDefinition;
            let continueProceed = false;
            for (let i = 0; i < columnDefinitionList.length; i++) {
                if (columnDefinitionList[i].columnName === e.dataField &&
                    (LDH.IsObjectNull(columnDefinitionList[i].columnType) ||
                        columnDefinitionList[i].columnType === "datetime")) {
                    continueProceed = true;
                }
            }
            if (continueProceed === false) return;

            let standardHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (n) {
                if (LDH.IsValueEmpty(n.value) === true ||
                    (!LDH.IsObjectNull(n.value.convertedToUtc) &&
                        n.value.convertedToUtc === true)) {
                    standardHandler(n);
                    return;
                }
                LeopardStaticUIConfig.Global_TempDateConvertion.push({
                    dataViewId: data.gridViewId,
                    convertFrom: LDH.GetLocalISODateString(n.value).replace(".000Z", "Z"),
                    convertTo: n.value.toISOString().replace(".000Z", "Z")
                });
                standardHandler(n);
            }
        }

        if (e.parentType === "filterRow" && (e.dataType === "string" || e.dataType === "guid")) {
            let columnDefinitionList = data.gridDefinition.columnDefinition;
            let continueProceed = false;
            let columnDef = null;

            for (let v = 0; v < columnDefinitionList.length; v++) {
                if (!LDH.IsObjectNull(columnDefinitionList[v].allowAutoComplete) &&
                    columnDefinitionList[v].allowAutoComplete === true &&
                    columnDefinitionList[v].columnName === e.dataField &&
                    (LDH.IsObjectNull(columnDefinitionList[v].columnType) ||
                        columnDefinitionList[v].columnType === "string" ||
                        columnDefinitionList[v].columnType === "guid")) {
                    continueProceed = true;
                    columnDef = columnDefinitionList[v];
                }
            }
            if (continueProceed === false) return;

            let autoCompleteOperation = "startswith";
            if (!LDH.IsObjectNull(columnDef.autoCompleteOperation) &&
                columnDef.autoCompleteOperation.length > 0) {
                autoCompleteOperation = columnDef.autoCompleteOperation;
            }
            let url = this.props.GetDataFromUrl.replace("?tableName=", "");
            let domainUrl = LDH.APIEndpointAdapter();

            e.editorName = "dxSelectBox";
            e.editorOptions.displayExpr = e.dataField;
            e.editorOptions.valueExpr = e.dataField;
            e.editorOptions.searchEnabled = true;
            e.editorOptions.searchMode = "contains";
            e.editorOptions.searchTimeout = 1000;
            e.editorOptions.minSearchLength = 1;
            e.editorOptions.showDropDownButton = false;
            e.editorOptions.showDataBeforeSearch = false;
            e.editorOptions.noDataText = "Enter value to search...";
            e.editorOptions.dataSource = LRH.InitCustomStoreForAutoComplete(
                domainUrl, url, autoCompleteOperation, columnDef.columnName,
                data.gridDefinition, null, null);
        }
    };

    gridViewCustomSave = (data) => {
        let pageSize = this.uiObjectInstance.gridViewInstance.pageSize();
        let config = {DevExtremeLayout: data.e, CustomLayout: {pageSize}};
        let configToSave = JSON.stringify(config);
        localStorage.setItem("GridViewLayoutState_" + data.gridViewId, configToSave);
    };

    gridViewCustomLoad = (data) => {
        let json = localStorage.getItem("GridViewLayoutState_" + data.gridViewId);
        if (LDH.IsValueEmpty(json) === false && !this.isGridViewLayoutStateLoaded) {
            let devExtremeLayout = JSON.parse(json).DevExtremeLayout;
            this.isGridViewLayoutStateLoaded = true;
            return devExtremeLayout;
        }
        this.isGridViewLayoutStateLoaded = true;
    };

    autoRefreshCountdownOnEnd = () => {
        this.refreshButtonOnClick({gridViewId: this.props.gridViewId});
    };

    commandColumnLinkOnClick = (data) => {
        let that = this;
        if ($("#" + data.id).length > 0 && $("#" + data.id).hasClass("disabled")) {
            return;
        }
        if (data.data.columnType === "photo-gallery") {
            LeopardStaticUIConfig.Global_PopupCustomColumnData = data;
            let instance = that.uiObjectInstance.popupPhotoGalleryInstance;
            instance.option("visible", true);
        } else if (data.data.columnType === "modify-row" ||
            data.data.columnType === "reset-password") {
            data.getDataFromUrl = that.props.GetDataFromUrl;
            data.getDataFromUrl = data.getDataFromUrl.replace("?tableName=", "");
            LeopardStaticUIConfig.Global_PopupCustomColumnData = data;

            let instance = that.uiObjectInstance.popupEditFormInstance;
            LeopardStaticUIConfig.Global_PopupTempObjectInstance = instance;
            instance.option("visible", true);
        } else if (data.data.columnType === "delete-row") {
            let keyObj = data.e.row.data[data.data.dataSourceId];
            let keyId = LDH.IsObjectNull(keyObj) || LDH.IsObjectNull(keyObj._value) ? "" : keyObj._value;

            LRH.ShowDialog("Are you sure you want to delete this row?", "Confirm Delete", function () {
                $(".leopard-application-loading-cover").show();
                LeopardAjaxHelper.GridViewCRUD_DeleteData(data.data.apiGatewayPath, keyId, function () {
                    $(".leopard-application-loading-cover").hide();
                    that.refreshGridViewOnPopupClosed();
                    LRH.ShowToast("This record has been successfully deleted.", "success", 5000);
                }, function (ex) {
                    $(".leopard-application-loading-cover").hide();
                    LRH.ShowToast("Failed to delete the record from the table. " +
                        JSON.stringify(ex.message), "error", 5000);
                });
            });
        } else if (data.data.columnType === "disable-row") {
            LRH.ShowDialog("Are you sure you want to disable this row?",
                "Confirm Disable", function () {

                });
        }
    };

    addPhotoGalleryPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupPhotoGalleryInstance = e.instance;
    };

    addEditFormPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupEditFormInstance = e.instance;
    };

    addDataImportPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupDataImportInstance = e.instance;
    };

    addDisposablePopupInstances = (e) => {
        for (let i = 0; i < e.length; i++) {
            this.disposablePopupInstances.push(e[i]);
        }
    };

    refreshGridViewOnPopupClosed = () => {
        let that = this;
        let instance = this.uiObjectInstance.gridViewInstance;
        if (instance !== undefined && instance !== null) {
            setTimeout(function () {
                let gridViewState = that.props.state.gridViewState;
                let currentState = that.getCurrentState(gridViewState, false);
                instance.option("dataSource", currentState.customStore);
            }, 3000);
        }
    };

    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.command === "edit") {
            let $links = $(".dx-link", $(e.cellElement));
            let rowIndex = e.row.rowIndex.toString();

            for (let i = 0; i < e.column.buttons.length; i++) {
                let button = e.column.buttons[i];
                if (!LPH.IsEnableAdminUserOnlyForAllCommandLinks(button, $links, rowIndex)) {
                    if (!LDH.IsObjectNull(button.columnType) &&
                        !LDH.IsObjectNull(button.countColumn) &&
                        !LDH.IsValueEmpty(button.countColumn) &&
                        button.columnType === "photo-gallery") {
                        let value = e.row.data[button.countColumn];
                        let hide = true;
                        if (LDH.IsValueEmpty(value) === false &&
                            (value.toString().toLowerCase() === "true" ||
                                value.toString().toLowerCase() !== "0")) {
                            hide = false;
                        }
                        if ($links.length > 0) {
                            for (let j = 0; j < $links.length; j++) {
                                if ($links[j].text.trim() === button.text) {
                                    $links[j].id = button.id + "_" + rowIndex;

                                    if (hide === true) {
                                        let className = $links[j].className;
                                        $links[j].style.opacity = "0.3";
                                        $links[j].className = className + " disabled";
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    customizeColumns = (e) => {
        let currentState = this.getCurrentState(this.props.state.gridViewState, true);
        if (LDH.IsObjectNull(currentState) || LDH.IsObjectNull(currentState.gridDefinition) ||
            LDH.IsObjectNull(currentState.gridDefinition.columnDefinition)) {
            return;
        }

        for (let i = 0; i < e.length; i++) {
            let columnDef = null;
            let dataField = e[i].dataField;
            columnDef = currentState.gridDefinition.columnDefinition.filter(c => {
                return c.columnName === dataField;
            });
            if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
                columnDef = columnDef[0];
            }

            let columnType = "string";
            if (columnDef !== null && columnDef.columnType !== undefined &&
                columnDef.columnType !== null && columnDef.columnType !== "") {
                columnType = columnDef.columnType;
            }

            // Visible settings.
            if (this.hasCustomizeColumnsInitialized === false) {
                let isVisible = true;
                if (columnDef !== null && columnDef.isVisible !== undefined &&
                    columnDef.isVisible !== null && columnDef.isVisible !== "") {
                    isVisible = columnDef.isVisible;
                }
                e[i].visible = isVisible;
            }

            // Visible Index settings.
            if (this.hasCustomizeColumnsInitialized === false) {
                let visibleIndex = e[i].index;
                if (columnDef !== null && columnDef.visibleIndex !== undefined &&
                    columnDef.visibleIndex !== null && columnDef.visibleIndex !== "") {
                    visibleIndex = columnDef.visibleIndex;
                }
                e[i].visibleIndex = visibleIndex;
            }

            // Default Operation settings.
            if (LDH.IsValueEmpty(e[i].selectedFilterOperation)) {
                let defaultOperation = "";
                if (LDH.IsValueEmpty(columnType) || columnType === "string") {
                    defaultOperation = "=";
                } else if (columnType === "guid") {
                    defaultOperation = "=";
                } else if (columnType === "number") {
                    defaultOperation = "=";
                } else if (columnType === "date") {
                    defaultOperation = "between";
                } else if (columnType === "datetime") {
                    defaultOperation = "between";
                }
                if (columnDef !== null && columnDef.defaultOperation !== undefined &&
                    columnDef.defaultOperation !== null && columnDef.defaultOperation !== "") {
                    defaultOperation = columnDef.defaultOperation;
                }
                e[i].selectedFilterOperation = defaultOperation;
            }
        }
        this.hasCustomizeColumnsInitialized = true;
    };

    onSelectionChanged = (e) => {
        if (this.props.useStateStore === null || this.props.useStateStore === false ||
            LDH.IsObjectNull(this.relationshipsLinkedToDataView) ||
            this.relationshipsLinkedToDataView.length === 0) {
            return;
        }
        for (let i = 0; i < this.relationshipsLinkedToDataView.length; i++) {
            let pId = this.relationshipsLinkedToDataView[i].parentDataViewId;
            let cId = this.relationshipsLinkedToDataView[i].childDataViewId;
            let features = this.relationshipsLinkedToDataView[i].interactiveFeatures;
            let dataSourceId = this.relationshipsLinkedToDataView[i].dataSourceId;
            let rowData = LDH.DeepClone(e.selectedRowsData[0]);
            let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
            let childDashboardItemId = cId.split(":")[0];
            if (childDashboardItemId === this.props.GridViewId) continue;

            for (let v = 0; v < listeners.length; v++) {
                if (listeners[v].dashboardItemId === childDashboardItemId) {
                    if (listeners[v].instance.NAME === "dxDataGrid" ||
                        listeners[v].instance.NAME === "dxChart") {
                        listeners[v].instance.clearSelection();
                    }
                    listeners[v].callback({
                        dataFromSource: rowData,
                        parentDataViewId: pId,
                        childDataViewId: cId,
                        features,
                        dataSourceId,
                        dataViewType: "datagrid",
                        parentGridDef: this.props.gridDefinition
                    });
                }
            }
        }
    };

    onRowClick = () => {
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = false;
        if (!LDH.IsObjectNull(LeopardStaticUIConfig.Global_DashboardAutoSelectInstance)) {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance.option("value", false);
        }
    };

    render() {
        if (this.disposingAllInstances) return null;

        let gridViewState = this.props.state.gridViewState;
        let currentState = this.getCurrentState(gridViewState, false);
        if (currentState === null) {
            return (<div>Retrieving data, please wait...</div>);
        }

        let gridDefinition = null;
        if (this.props.useStateStore === true) {
            gridDefinition = this.gridDefinition;
        } else {
            gridDefinition = currentState.gridDefinition;
        }

        this.enableAutoRefresh = this.props.enableAutoRefresh;
        if (LDH.IsObjectNull(this.enableAutoRefresh)) {
            this.enableAutoRefresh = gridDefinition.enableAutoRefresh;
        }
        if (LDH.IsValueEmpty(this.enableAutoRefresh)) {
            this.enableAutoRefresh = false;
        }

        this.autoRefreshInterval = this.props.autoRefreshInterval;
        if (LDH.IsObjectNull(this.autoRefreshInterval)) {
            this.autoRefreshInterval = gridDefinition.autoRefreshInterval;
        }
        if (LDH.IsValueEmpty(this.autoRefreshInterval)) {
            this.autoRefreshInterval = 30;
        }

        this.customColumnOverallWidth = this.props.customColumnOverallWidth;
        if (LDH.IsObjectNull(this.customColumnOverallWidth)) {
            this.customColumnOverallWidth = gridDefinition.customColumnOverallWidth;
        }
        if (LDH.IsValueEmpty(this.customColumnOverallWidth)) {
            this.customColumnOverallWidth = 200;
        }

        this.showAutoRefreshSwitch = this.props.showAutoRefreshSwitch;
        if (LDH.IsObjectNull(this.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = gridDefinition.showAutoRefreshSwitch;
        }
        if (LDH.IsValueEmpty(this.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = true;
        }

        let showAddButton = false;
        let customColumnForAddRow = null;
        if (!LDH.IsObjectNull(gridDefinition.customColumnConfiguration) &&
            !LDH.IsObjectNull(gridDefinition.customColumnConfiguration.customColumns) &&
            gridDefinition.customColumnConfiguration.customColumns.length > 0) {
            for (let i = 0; i < gridDefinition.customColumnConfiguration.customColumns.length; i++) {
                let customColumn = gridDefinition.customColumnConfiguration.customColumns[i];
                if (customColumn.columnType === "add-row") {
                    showAddButton = true;
                    customColumnForAddRow = customColumn;
                }
            }
        }

        return (
            <React.Fragment>
                <LeopardGridViewToolbar groupingEnabled={this.state.groupingEnabled}
                                        groupingButtonOnClick={(e) => this.groupingButtonOnClick(e)}
                                        gridViewId={this.props.GridViewId} minHeight={this.props.minHeightToolbar}
                                        addRowButtonOnClick={(e) => this.addRowButtonOnClick({
                                            e, data: customColumnForAddRow,
                                            limitedColumns: this.props.columnFieldList.limitedColumns,
                                            definition: gridDefinition
                                        })}
                                        explicitFilterOption={this.props.explicitFilterOption}
                                        showAutoRefreshSwitch={this.showAutoRefreshSwitch}
                                        autoRefreshCountdownOnEnd={(e) => this.autoRefreshCountdownOnEnd(e)}
                                        autoRefreshOnValueChanged={(e) => this.autoRefreshOnValueChanged(e)}
                                        gridDefinition={gridDefinition} autoRefreshInterval={this.autoRefreshInterval}
                                        applyFilterButtonOnClick={(e) => this.applyFilterButtonOnClick(e)}
                                        clearFilterButtonOnClick={(e) => this.clearFilterButtonOnClick({
                                            e: e,
                                            gridDefinition: gridDefinition
                                        })}
                                        refreshButtonOnClick={() => this.refreshButtonOnClick({gridViewId: this.props.GridViewId})}
                                        viewOptionsButtonOnClick={() => this.viewOptionsButtonOnClick({gridViewId: this.props.GridViewId})}
                                        exportButtonOnClick={() => this.exportButtonOnClick({gridViewId: this.props.GridViewId})}
                                        importButtonOnClick={() => this.importButtonOnClick({gridViewId: this.props.GridViewId})}
                                        importDataCallback={(data) => this.importDataCallback({
                                            gridViewId: this.props.GridViewId,
                                            data: data.data
                                        })}
                                        showAddButton={showAddButton} enableAutoRefresh={this.enableAutoRefresh}/>

                <BuildLeopardGridView setGridViewInstance={this.setGridViewInstance} thisComp={this}
                                      currentState={currentState} gridDefinition={gridDefinition}
                                      onRowExpanding={(e) => this.onRowExpanding(e)}
                                      onInitialized={(e) => this.onInitialized(e)}
                                      onDataErrorOccurred={(e) => this.onDataErrorOccurred(e)}
                                      onToolbarPreparing={(e) => this.onToolbarPreparing(e)}
                                      gridViewId={this.props.GridViewId}
                                      viewOptionsText={this.props.viewOptionsText}
                                      groupingEnabled={false} buttonNextOnClick={(e) => this.buttonNextOnClick(e)}
                                      buttonPrevOnClick={(e) => this.buttonPrevOnClick(e)}
                                      onCellPrepared={(e) => this.onCellPrepared(e)}
                                      gridViewCustomSave={(e) => this.gridViewCustomSave(e)}
                                      gridViewCustomLoad={(e) => this.gridViewCustomLoad(e)}
                                      onEditorPreparing={(e) => this.onEditorPreparing(e)}
                                      explicitFilterOption={this.props.explicitFilterOption}
                                      columnResizingMode={this.props.columnResizingMode}
                                      filterBuilderValue={this.props.filterBuilderValue}
                                      onRowClick={(e) => this.onRowClick(e)}
                                      onSelectionChanged={(e) => this.onSelectionChanged(e)}
                                      customizeColumns={(e) => this.customizeColumns(e)}
                                      limitedColumns={this.props.columnFieldList.limitedColumns}
                                      onContentReady={(e) => this.onContentReady(e)}
                                      onExporting={(e) => this.onExporting(e)}/>
            </React.Fragment>
        );
    }
}

const BuildLeopardGridView = ({
                                  setGridViewInstance, currentState, onContentReady, explicitFilterOption, onToolbarPreparing, onRowExpanding,
                                  gridViewId, buttonNextOnClick, buttonPrevOnClick, viewOptionsText, limitedColumns, onInitialized,
                                  columnResizingMode, filterBuilderValue, onDataErrorOccurred, thisComp, onEditorPreparing, gridDefinition,
                                  customizeColumns, onCellPrepared, onSelectionChanged, onRowClick, onExporting
                              }) => {
    if (LDH.IsValueEmpty(explicitFilterOption) === true) {
        explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
    }

    thisComp.optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
    if (LDH.IsValueEmpty(thisComp.optimizePagerForLargeDataset)) {
        thisComp.optimizePagerForLargeDataset = true;
    }

    thisComp.defaultPageSize = gridDefinition.defaultPageSize;
    if (LDH.IsValueEmpty(thisComp.defaultPageSize)) {
        thisComp.defaultPageSize = 10;
    }

    thisComp.customColumnConfiguration = gridDefinition.customColumnConfiguration;
    LDH.ParseDevExtremeFilterString(filterBuilderValue);

    let columnComponent = LeopardGridViewColumnBuilder(gridDefinition, limitedColumns, thisComp, currentState);
    if (!LDH.IsObjectNull(thisComp.customColumnConfiguration) &&
        !LDH.IsObjectNull(thisComp.customColumnConfiguration.customColumns) &&
        thisComp.customColumnConfiguration.customColumns.length > 0) {
        let buttons = [];

        for (let i = 0; i < thisComp.customColumnConfiguration.customColumns.length; i++) {
            let customColumn = thisComp.customColumnConfiguration.customColumns[i];
            let id = LDH.GenerateGuid();

            if (customColumn.columnType === "add-row") {
                continue;
            }
            buttons.push({
                text: customColumn.linkText, countColumn: customColumn.countColumnName,
                enableAdminUserOnly: customColumn.enableAdminUserOnly,
                onClick(e) {
                    thisComp.commandColumnLinkOnClick({
                        e, data: customColumn, limitedColumns,
                        definition: gridDefinition,
                        id: id + "_" + e.row.rowIndex.toString()
                    });
                }, columnType: customColumn.columnType, id
            });
        }

        let width = thisComp.customColumnConfiguration.customColumnOverallWidth;
        if (LDH.IsObjectNull(columnComponent) === false && buttons.length > 0) {
            columnComponent.push(<Column key={"CustomColumn"} width={width} type={"buttons"} buttons={buttons}/>);
        }
    }

    return (
        <React.Fragment>
            <DataGrid cacheEnabled={LeopardStaticUIConfig.GridView_CacheEnabled}
                      dataSource={currentState.customStore} showBorders={LeopardStaticUIConfig.showBorders}
                      onContentReady={(e) => onContentReady(e)}
                      allowColumnResizing={LeopardStaticUIConfig.GridView_AllowColumnResizing}
                      columnMinWidth={LeopardStaticUIConfig.GridView_ColumnMinWidth}
                      columnAutoWidth={LeopardStaticUIConfig.GridView_ColumnAutoWidth}
                      onCellPrepared={(e) => onCellPrepared(e)}
                      onSelectionChanged={(e) => onSelectionChanged(e)}
                      onRowClick={(e) => onRowClick(e)}
                      onExporting={(e) => onExporting(e)}
                      highlightChanges={LeopardStaticUIConfig.GridView_HighlightChanges}
                      onEditorPreparing={(e) => onEditorPreparing({
                          e, gridViewId, gridDefinition
                      })}
                      customizeColumns={(e) => customizeColumns(e)}
                      repaintChangesOnly={LeopardStaticUIConfig.GridView_RepaintChangesOnly}
                      columnResizingMode={columnResizingMode} filterValue={filterBuilderValue}
                      allowColumnReordering={LeopardStaticUIConfig.GridView_AllowColumnReordering}
                      hoverStateEnabled={LeopardStaticUIConfig.GridView_HoverStateEnabled} id={gridViewId}
                      onRowExpanding={(e) => onRowExpanding(e)} ref={setGridViewInstance}
                      className="leopard-gridview-control" onDataErrorOccurred={(e) => onDataErrorOccurred(e)}
                      onInitialized={(e) => onInitialized(e)} onToolbarPreparing={(e) => onToolbarPreparing(e)}
                      rowAlternationEnabled={LeopardStaticUIConfig.GridView_RowAlternationEnabled}>
                {
                    LDH.IsObjectNull(gridDefinition) ? "" : columnComponent
                }
                <ColumnChooser enabled={LeopardStaticUIConfig.ColumnChooser_Enabled} title={viewOptionsText}/>
                <Editing allowUpdating={false} allowDeleting={false} allowAdding={false}/>
                <Export enabled={true} fileName={viewOptionsText} allowExportSelectedData={true}/>
                <Selection mode={"single"}></Selection>
                <LoadPanel enabled={LeopardStaticUIConfig.LoadPanel_ShowIndicator}
                           showPane={LeopardStaticUIConfig.LoadPanel_ShowPane}/>
                <FilterRow visible={LeopardStaticUIConfig.GridView_FilterRow} applyFilter={explicitFilterOption}/>
                <Sorting mode={LeopardStaticUIConfig.Sorting_Mode}/>
                <HeaderFilter visible={LeopardStaticUIConfig.HeaderFilter_Visible}/>
                <ColumnFixing enabled={LeopardStaticUIConfig.ColumnFixing_Enabled}/>
                {
                    !LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false ?
                        <Paging defaultPageSize={thisComp.defaultPageSize} enabled={false}></Paging> :
                        <Paging defaultPageSize={thisComp.defaultPageSize}
                                enabled={thisComp.optimizePagerForLargeDataset === false}>
                        </Paging>
                }
                {
                    !LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false ?
                        <Pager visible={false}></Pager> :
                        <Pager visible={thisComp.optimizePagerForLargeDataset === false}
                               infoText={"{0} of {1} ({2})"}
                               showPageSizeSelector={thisComp.optimizePagerForLargeDataset === false}
                               allowedPageSizes={LeopardStaticUIConfig.Pager_allowedPageSizes}
                               showInfo={thisComp.optimizePagerForLargeDataset === false}
                               showNavigationButtons={thisComp.optimizePagerForLargeDataset === false}>
                        </Pager>
                }
                {
                    !LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false ?
                        <RemoteOperations paging={true} filtering={true} grouping={true} groupPaging={true} sorting={true}/> :
                        thisComp.optimizePagerForLargeDataset ?
                            <RemoteOperations paging={false} filtering={true} grouping={true} groupPaging={true} sorting={true}/> :
                            <RemoteOperations paging={true} filtering={true} grouping={true} groupPaging={true} sorting={true}/>
                }
            </DataGrid>
            {
                (!LDH.IsObjectNull(gridDefinition.enablePagination) && gridDefinition.enablePagination === false) ||
                thisComp.optimizePagerForLargeDataset === false ? "" :
                    <div className={"leopard-page-container"} id={"GridViewPager_" + gridViewId}>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 10 ? "selected" : "")} pagesize="10">10</div>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 25 ? "selected" : "")} pagesize="25">25</div>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 50 ? "selected" : "")} pagesize="50">50</div>
                        <div className={"leopard-page-button-container next"}>
                            <i className={"fas fa-chevron-right"} style={{fontSize: "25px", float: "right"}}
                               onClick={(e) => buttonNextOnClick({e, gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "5px"}} href={"#_"}
                               onClick={(e) => buttonNextOnClick({e, gridViewId})}>Next</a>
                        </div>
                        <div className={"leopard-page-button-container previous"}>
                            <i className={"fas fa-chevron-left"} style={{fontSize: "25px"}}
                               onClick={(e) => buttonPrevOnClick({e, gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "10px"}} href={"#_"}
                               onClick={(e) => buttonPrevOnClick({e, gridViewId})}>Previous</a>
                        </div>
                        <div className={"leopard-page-infotext"}>
                            Page <span className={"leopard-pagenumber-current"}></span>
                        </div>
                    </div>
            }
            <LeopardAttachmentPopup
                popupPhotoGalleryInstance={thisComp.addPhotoGalleryPopupInstance} popupWidth={"1024px"}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                popupHeight={"695px"} popupTitle={"Attachments"}
            />
            <LeopardFormEditor
                popupEditFormInstance={thisComp.addEditFormPopupInstance} popupWidth={"800px"}
                popupTitle={"Edit Row"}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                popupOnClosed={(e) => thisComp.refreshGridViewOnPopupClosed(e)}
            />
            <LeopardDataImportPopup
                popupTitle={"Import"} contentHeight={"100%"} dataViewId={gridViewId}
                addDisposablePopupInstances={(e) => thisComp.addDisposablePopupInstances(e)}
                popupDataImportInstance={thisComp.addDataImportPopupInstance}
                popupOnClosed={(e) => thisComp.refreshGridViewOnPopupClosed(e)}/>
        </React.Fragment>
    );
};

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitCustomStore: (store, id) => {
            dispatch(InitCustomStore(store, id));
        },
        UpdateGridViewDefinition: (data, id) => {
            dispatch(UpdateGridViewDefinition(data, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardGridView);
